import React from 'react';
import LoginForm from 'mirage-components/dist/LoginForm';
import Column from 'mirage-components/dist/Column';
import { login } from 'mirage-api-client/dist/auth';
import { Redirect } from 'react-router-dom';
import { useUser } from '../entities/UserContext';
import ForgotPassword from '../components/ForgotPassword';
import { usePassword } from '../entities/PasswordContext';

function getRedirectUrl(searchString) {
  const params = new URLSearchParams(searchString);

  return params.get('redirect') || '/';
}

export default function LoginPage() {
  const { setPassword } = usePassword();

  async function onLogin({ email, password }) {
    const result = await login(email, password, true);
    if (result !== true) {
      throw new Error(
        'The username or password you entered is not correct. Please try again.'
      );
    }

    setPassword(password);
  }

  const user = useUser();
  const redirectUrl = getRedirectUrl(window.location.search);

  if (user) return <Redirect to={redirectUrl} />;

  return (
    <Column style={{ height: '100%' }}>
      <LoginForm onSubmit={onLogin} forgotPassword={<ForgotPassword />} />
    </Column>
  );
}
