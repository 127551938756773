import { Typography } from '@material-ui/core';
import Column from 'mirage-components/dist/Column';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import { useStyles } from '../styles';

RequestStatus.prototype = {
  title: PropTypes.string.isRequired,
};

export default function RequestStatus({ title }) {
  const classes = useStyles();

  return (
    <Column className={classes.root}>
      <Link to="/">
        <img src={logo} alt="Mirage ID" className={classes.logo} />
      </Link>
      <Column className={classes.wrapper}>
        <Typography className={classes.title}>{title}</Typography>
      </Column>
    </Column>
  );
}
