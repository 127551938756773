import { makeStyles } from '@material-ui/core';
import { toPx } from '../../lib/utils';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.white,
  },
  logo: {
    width: 185,
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: toPx(theme.spacing(5)),
    lineHeight: toPx(theme.spacing(6.5)),
    fontWeight: '500',
    textAlign: 'center',
    color: theme.palette.primary.darkBlue,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 622,
    padding: theme.spacing(3),
    gap: toPx(theme.spacing()),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.lightGray,
    marginTop: theme.spacing(3),
  },
  heightContainer: {
    maxHeight: 120,
    display: 'flex',
    gap: toPx(theme.spacing()),
    overflowY: 'scroll',
  },
  row: {
    display: 'flex',
    width: 622,
    justifyContent: 'space-between',
  },
  label: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: toPx(theme.spacing(2)),
    fontWeight: '500',
    lineHeight: toPx(theme.spacing(3)),
    textAlign: 'left',
    color: theme.palette.primary.charcoalGray,
  },
  text: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: toPx(theme.spacing(2)),
    fontWeight: '400',
    lineHeight: toPx(theme.spacing(3)),
    textAlign: 'left',
    color: theme.palette.primary.charcoalGray,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  actionButton: {
    fontFamily: 'Roboto',
    fontSize: toPx(theme.spacing(2)),
    fontWeight: '500',
    lineHeight: toPx(theme.spacing(3)),
    padding: theme.spacing(1, 3),
    borderRadius: 100,
  },
  wrapper: {
    maxWidth: 778,
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
    background: theme.palette.primary.black600,
  },
  paper: {
    maxWidth: 360,
    '& .MuiDialogContent-root': {
      padding: theme.spacing(1.5, 3, 2.5),
      '& .MuiDialogContentText-root': {
        fontFamily: 'Roboto',
        fontSize: toPx(theme.spacing(2)),
        fontWeight: '400',
        lineHeight: toPx(theme.spacing(3)),
        color: theme.palette.primary.black870,
        textAlign: 'center',
        marginBottom: 0,
      },
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1.5, 3),
    },
  },
  buttonModal: {
    flex: 1,
    borderRadius: 100,
  },
}));
