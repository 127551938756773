import React from 'react';
import UserSettings from 'mirage-components/dist/UserSettings';
import {
  useUser,
  update as updateUser,
  usePaymentMethod,
  setPaymentSource,
  updatePassword,
  useTokens,
  createApiToken,
  deleteApiToken,
} from 'mirage-api-client/dist/user';
import Column from 'mirage-components/dist/Column';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import config from '../config';

const useStyles = makeStyles({
  container: {
    maxWidth: 1000,
    margin: 'auto',
    marginTop: 20,
  },
});

export default function UserSettingsPage() {
  const classes = useStyles();
  const { newSnackBar } = useSnackBar();

  const [paymentMethod, _error, inProgress] = usePaymentMethod({ fetch: true });

  const [user] = useUser({ fetch: true, dependencies: [paymentMethod] });

  const [tokens] = useTokens();

  const flags = useFlags();

  async function handlePasswordChange({ oldPassword, password }) {
    try {
      await updatePassword(oldPassword, password);
    } catch (err) {
      console.error(err);
      newSnackBar({
        type: 'error',
        message: err.message,
        position: { vertical: 'top', horizontal: 'center' },
      });
    }
  }

  function handleTokenCreate(fields) {
    return createApiToken(fields);
  }

  function handleTokenDelete(id) {
    return deleteApiToken(id);
  }

  if (!user) return null;

  return (
    <Column className={classes.container}>
      <UserSettings
        onSubmit={updateUser}
        onUpdatePaymentMethod={setPaymentSource}
        paymentMethod={!paymentMethod && inProgress ? undefined : paymentMethod}
        user={user}
        pricingUrl={`${config.websiteUrl}/pricing`}
        contactUrl={`${config.websiteUrl}/contact`}
        stripeToken={config.stripeToken}
        onPasswordChange={handlePasswordChange}
        {...(flags.enterpriseApi
          ? {
              onCreateToken: handleTokenCreate,
              onDeleteToken: handleTokenDelete,
              tokens,
            }
          : {})}
      />
    </Column>
  );
}
