import React from 'react';
import PasswordModal from 'mirage-components/dist/User/PasswordModal';
import { updateChildPassword } from 'mirage-api-client/dist/user';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { usePassword } from '../entities/PasswordContext';
import RecoveryKey from './RecoveryKey';

export default function PasswordForm() {
  const { password } = usePassword();
  const [open, setOpen] = React.useState(true);
  const [mirageEncryptedKey, setMirageEncryptedKey] = React.useState(null);
  const { newSnackBar } = useSnackBar();

  async function onUpdatePassword(data) {
    try {
      setMirageEncryptedKey(
        await updateChildPassword(data.oldPassword, data.password)
      );
      newSnackBar({
        type: 'success',
        message: 'Password updated',
      });
      setOpen(false);
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }

  return (
    <>
      <PasswordModal
        onUpdatePassword={onUpdatePassword}
        open={open}
        oldPassword={password}
      />
      {mirageEncryptedKey && (
        <RecoveryKey mirageEncryptedKey={mirageEncryptedKey} />
      )}
    </>
  );
}
