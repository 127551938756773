import React from 'react';
import Container from '@material-ui/core/Container';
import Identities from '../components/Identities';

export default function IdentitiesPage() {
  return (
    <Container>
      <Identities />
    </Container>
  );
}
