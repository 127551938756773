import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PhoneModal from 'mirage-components/dist/User/PhoneModal';
import {
  update as updateUser,
  useUser as useScopedUser,
} from 'mirage-api-client/dist/user';
import { useUser } from '../entities/UserContext';
import { usePassword } from '../entities/PasswordContext';
import SplashScreen from './SplashScreen';
import PasswordForm from '../components/PasswordForm';
import DataRecovery from '../components/DataRecovery';

export default function AuthGate({ children }) {
  const user = useUser();
  const { validKey } = usePassword();
  const location = useLocation();
  // scopedUser is used to allow dismissing PhoneModal.
  const [scopedUser] = useScopedUser();

  // if the user hasn't loaded yet show the splashscreen (to prevent a login-screen flash)
  if (user === false) {
    return <SplashScreen />;
  }

  return user ? (
    <>
      {children}
      {user && user.tmpPassword && <PasswordForm />}
      {validKey === false && <DataRecovery user={user} />}
      {scopedUser && !scopedUser.parentUID && !scopedUser.phone && (
        <PhoneModal user={scopedUser} onUpdatePhone={updateUser} />
      )}
    </>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        search: `?redirect=${encodeURIComponent(
          // eslint-disable-next-line no-undef
          window.location.pathname +
            // eslint-disable-next-line no-undef
            window.location.search +
            // eslint-disable-next-line no-undef
            window.location.hash
        )}`,
        state: { from: location },
      }}
    />
  );
}
