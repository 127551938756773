import { Backdrop, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import {
  getWhiteListByIdentity,
  responseDomainAccessRequest,
  sendDomainAccessRequest,
  validateTokenRequest,
} from 'mirage-api-client/dist/identity';
import React, { useEffect, useState } from 'react';

import UnblockInfo from './components/UnblockInfo';
import RequestStatus from './components/RequestStatus';
import useAPIClientInit from '../../entities/useAPIClientInit';
import { base64ToJson } from '../../lib/utils';
import SplashScreen from '../../Shell/SplashScreen';
import { FILTER_SUPPORT_STATUS, FILTER_SUPPORT_TITLE } from './constants';
import { useStyles } from './styles';

const FilterSupportPage = () => {
  useAPIClientInit();
  const classes = useStyles();
  const { data } = useParams();

  const [isRequesting, setIsRequesting] = useState(false);
  const [status, setStatus] = useState('');
  const [dataJson, setDataJson] = useState(base64ToJson(data));
  const [whiteList, setWhiteList] = useState('');

  const handleRequest = async (requestAction, token, statusRequest = '') => {
    try {
      setIsRequesting(true);
      await requestAction(token, statusRequest);
      if (statusRequest) {
        setDataJson(pre => ({ ...pre, status: statusRequest }));
      }
      setStatus(FILTER_SUPPORT_STATUS.COMPLETE);
    } catch (e) {
      setStatus(FILTER_SUPPORT_STATUS.FAILED);
    } finally {
      setIsRequesting(false);
    }
  };

  const handleActionRequest = statusRequest => {
    handleRequest(responseDomainAccessRequest, dataJson.token, statusRequest);
  };

  useEffect(() => {
    const handleValidateToken = async originalData => {
      try {
        setIsRequesting(true);
        await validateTokenRequest(originalData.token);
        if (originalData.status === FILTER_SUPPORT_STATUS.APPROVE) {
          const response = await getWhiteListByIdentity(
            originalData.identity_id,
            originalData.filter_company.key
          );
          if (response.status === 200) {
            setWhiteList(response.data.data.whiteList);
          }
        }
        if (originalData.status === FILTER_SUPPORT_STATUS.UNBLOCK) {
          await handleRequest(sendDomainAccessRequest, originalData.token);
        }
        if (originalData.status === FILTER_SUPPORT_STATUS.DENY) {
          await handleRequest(
            responseDomainAccessRequest,
            originalData.token,
            FILTER_SUPPORT_STATUS.DENIED
          );
        }
      } catch (_error) {
        setStatus(FILTER_SUPPORT_STATUS.FAILED);
      } finally {
        setIsRequesting(false);
      }
    };

    const originalData = base64ToJson(data);
    if (originalData.token) {
      handleValidateToken(originalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (status === FILTER_SUPPORT_STATUS.FAILED) {
    return <RequestStatus title={FILTER_SUPPORT_TITLE.EXPIRED} />;
  }
  if (status === FILTER_SUPPORT_STATUS.COMPLETE) {
    if (dataJson.status === FILTER_SUPPORT_STATUS.UNBLOCK) {
      return <RequestStatus title={FILTER_SUPPORT_TITLE.SENDED} />;
    }
    if (dataJson.status === FILTER_SUPPORT_STATUS.DENIED) {
      return <RequestStatus title={FILTER_SUPPORT_TITLE.DENIED} />;
    }
    if (dataJson.status === FILTER_SUPPORT_STATUS.APPROVED) {
      return <RequestStatus title={FILTER_SUPPORT_TITLE.SUCCESSFULLY} />;
    }
  }
  return (
    <>
      {!isRequesting && dataJson.status === FILTER_SUPPORT_STATUS.APPROVE ? (
        <UnblockInfo
          dataJson={dataJson}
          whiteList={whiteList}
          onDeny={() => handleActionRequest(FILTER_SUPPORT_STATUS.DENIED)}
          onAllow={() => handleActionRequest(FILTER_SUPPORT_STATUS.APPROVED)}
        />
      ) : (
        <SplashScreen />
      )}
      {isRequesting && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
};

export default FilterSupportPage;
