import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import ForgotPassword from 'mirage-components/dist/ForgotPassword';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { requestResetPassword } from 'mirage-api-client/dist/user';

const useStyles = makeStyles({
  link: {
    display: 'block',
    cursor: 'pointer',
    marginTop: 4,
  },
});

export default function ForgotPasswordLink() {
  const classes = useStyles();
  const { newSnackBar } = useSnackBar();
  async function handleSubmit(fields) {
    try {
      await requestResetPassword(fields.email);
      newSnackBar({
        type: 'success',
        message: 'Reset password email sent successfully',
      });
    } catch (error) {
      newSnackBar({
        type: 'error',
        message: (error.response && error.response.data.error) || error.message,
        position: { vertical: 'top', horizontal: 'center' },
      });
      throw error;
    }
  }
  return (
    <ForgotPassword onSubmit={handleSubmit}>
      <Link className={classes.link}>Forgot password?</Link>
    </ForgotPassword>
  );
}
