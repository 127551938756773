import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from 'mirage-components/dist/Theme';
import GlobalProviders from './GlobalProviders';
import Router from './Router';

export default function Shell() {
  return (
    <GlobalProviders>
      <Theme>
        <CssBaseline />
        <Router />
      </Theme>
    </GlobalProviders>
  );
}
