const firebase = {
  apiKey: 'AIzaSyAMAftpeoAJ6p_V95Yoek2jpUeIp1OhU70',
  authDomain: 'mirage-15494.firebaseapp.com',
  databaseURL: 'https://mirage-15494.firebaseio.com/',
  projectId: 'mirage-15494',
  storageBucket: 'mirage-15494.appspot.com',
  // messagingSenderId: "1039217910993"
};

const baseUrl = 'https://api.mirageid.com';
const websiteUrl = 'https://mirageid.com';
const docUrl = 'https://docs.mirageid.com';
const helpUrl = 'https://help.mirageid.com/en';

const launchDarkly = {
  clientID: '5dc308f0fec6f208c5830096',
};

const stripeToken = 'pk_live_qexsbiKAPM2NJfa7ymKY9Nz6';

export default {
  firebase,
  baseUrl,
  websiteUrl,
  launchDarkly,
  stripeToken,
  docUrl,
  helpUrl,
};
