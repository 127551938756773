import React from 'react';
import PasswordModal from 'mirage-components/dist/User/PasswordModal';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { resetPassword } from 'mirage-api-client/dist/user';
import { useSnackBar } from 'mirage-components/dist/SnackBar';

export default function PasswordReset() {
  const location = useLocation();
  const { token } = useParams();
  const { newSnackBar } = useSnackBar();
  const [complete, setComplete] = React.useState(false);

  async function onUpdatePassword(data) {
    try {
      await resetPassword(data.password, token);
      newSnackBar({
        message: 'Password Reset',
        type: 'success',
      });
      setComplete(true);
    } catch (e) {
      newSnackBar({
        message: e.message,
        type: 'error',
        position: { vertical: 'top', horizontal: 'center' },
      });
    }
  }

  if (complete || !token) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return (
    <PasswordModal
      onUpdatePassword={onUpdatePassword}
      open
      oldPassword={true}
    />
  );
}
