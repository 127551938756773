import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(4)}px`,
  },
  formTitle: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  formSubTitle: {
    marginBottom: `${theme.spacing(1)}px`,
  },
  formControl: {
    margin: `${theme.spacing(1)}px`,
  },
  submitArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: `${theme.spacing(2)}px`,
  },
  submitButton: {
    marginTop: `${theme.spacing(4)}px`,
    minWidth: `${theme.spacing(36)}px`,
  },
  agreeHelperText: {
    textAlign: 'center',
  },
}));
