import { AppBar, Link as MaterialLink, Toolbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import React from 'react';

import { trialDaysRemaining, isTrialExpired } from '../../lib/utils';
import {
  accountSuspended,
  parentAccountSuspended,
} from '../../lib/ErrorMessages';
import { useStyles } from './styles';
import logo from '../../assets/logo.png';

export default function TopBar({
  open,
  user,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="default"
      elevation={1}
    >
      {user && user.status === 'trial' && (
        <Alert
          severity={isTrialExpired(user.trialExpiration) ? 'error' : 'warning'}
          elevation={0}
          variant="filled"
          className={classes.alert}
        >
          {isTrialExpired(user.trialExpiration)
            ? `Your trial has expired.`
            : `You have ${trialDaysRemaining(
                user.trialExpiration
              )} days remaining in
            your trial.`}{' '}
          {!user.parentUID ? (
            <Link to="/settings">
              <MaterialLink
                className={classes.addPaymentLink}
                component="span"
                underline="always"
              >
                Add a payment method to continue using Mirage ID
              </MaterialLink>
            </Link>
          ) : (
            'To continue using Mirage, please ask your administrator to add a payment method.'
          )}
        </Alert>
      )}

      {user && user.status === 'suspended' && (
        <Alert
          severity="error"
          elevation={0}
          variant="filled"
          className={classes.alert}
        >
          {!user.parentUID ? parentAccountSuspended : accountSuspended}
        </Alert>
      )}

      <Toolbar variant="dense">
        <IconButton
          color="inherit"
          aria-label={open ? 'open drawer' : 'close drawer'}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="Mirage ID" className={classes.logo} />
      </Toolbar>
    </AppBar>
  );
}
