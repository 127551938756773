import React from 'react';
import Container from '@material-ui/core/Container';
import Users from '../components/Users';

export default function UsersPage() {
  return (
    <Container>
      <Users />
    </Container>
  );
}
