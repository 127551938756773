import React, { useState } from 'react';
import { allCountries } from 'country-region-data';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { createUser } from 'mirage-api-client/dist/user';
import { useStyles } from './styles';
import config from '../../config';
import { signupFormModel } from '../../models/signup';
import { SuccessSignup } from './success-signup/success-signup';

const { websiteUrl } = config;

export const Signup = () => {
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupFormModel),
  });
  const [successData, setSuccessData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const onSubmit = async data => {
    try {
      setIsSubmitting(true);
      setError('');
      const result = await createUser(data);
      setSuccessData(result);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (successData.recoveryKey) return <SuccessSignup data={successData} />;

  return (
    <Container className={classes.container}>
      <Typography variant="h4" align="center" className={classes.formTitle}>
        Signup For MirageID
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={10}>
          <Grid container item xs={12} md={6} direction="column">
            <Typography
              variant="h6"
              align="center"
              className={classes.formSubTitle}
            >
              Account Info
            </Typography>
            <div className={classes.formSection}>
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                margin="dense"
                error={errors.email && !!errors.email.message}
                helperText={errors.email && errors.email.message}
                {...register('email')}
              />
              <FormControl variant="outlined" margin="dense">
                <InputLabel>Account Type</InputLabel>
                <Select
                  variant="outlined"
                  label="Account Type"
                  margin="dense"
                  {...register('accountType')}
                  defaultValue={query.get('account_type') || 'business'}
                >
                  <MenuItem value="home_office">Home Office</MenuItem>
                  <MenuItem value="business">Business</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                label="Password"
                type="password"
                fullWidth
                margin="dense"
                error={errors.password && !!errors.password.message}
                helperText={errors.password && errors.password.message}
                {...register('password')}
              />
              <TextField
                variant="outlined"
                type="password"
                label="Confirm Password"
                fullWidth
                margin="dense"
                error={
                  errors.confirmPassword && !!errors.confirmPassword.message
                }
                helperText={
                  errors.confirmPassword && errors.confirmPassword.message
                }
                {...register('confirmPassword')}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              align="center"
              className={classes.formSubTitle}
            >
              Contact Info
            </Typography>
            <div className={classes.formSection}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="First Name"
                    fullWidth
                    margin="dense"
                    error={errors.firstName && !!errors.firstName.message}
                    helperText={errors.firstName && errors.firstName.message}
                    {...register('firstName')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    fullWidth
                    margin="dense"
                    error={errors.lastName && !!errors.lastName.message}
                    helperText={errors.lastName && errors.lastName.message}
                    {...register('lastName')}
                  />
                </Grid>
              </Grid>
              <TextField
                variant="outlined"
                label="Phone Number"
                margin="dense"
                {...register('phone')}
                error={errors.phone && !!errors.phone.message}
                helperText={errors.phone && errors.phone.message}
              />
              <TextField
                variant="outlined"
                label="Address"
                fullWidth
                margin="dense"
                {...register('address')}
                error={errors.address && !!errors.address.message}
                helperText={errors.address && errors.address.message}
              />
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    label="City"
                    fullWidth
                    margin="dense"
                    {...register('city')}
                    error={errors.city && !!errors.city.message}
                    helperText={errors.city && errors.city.message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="State"
                    fullWidth
                    margin="dense"
                    {...register('state')}
                    error={errors.state && !!errors.state.message}
                    helperText={errors.state && errors.state.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="Zipcode"
                    fullWidth
                    margin="dense"
                    {...register('zipcode')}
                    error={errors.zipcode && !!errors.zipcode.message}
                    helperText={errors.zipcode && errors.zipcode.message}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    error={errors.country && !!errors.country.message}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      variant="outlined"
                      label="Country"
                      margin="dense"
                      {...register('country')}
                      defaultValue="US"
                    >
                      {allCountries.map(item => (
                        <MenuItem key={item[1]} value={item[1]}>
                          {item[0]}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.country && errors.country.message && (
                      <FormHelperText>{errors.country.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <div className={classes.submitArea}>
          <FormControl
            required
            error={errors.acceptedToS && !!errors.acceptedToS.message}
          >
            <FormControlLabel
              control={
                <Checkbox color="primary" {...register('acceptedToS')} />
              }
              label={
                <Typography>
                  I agree to the{' '}
                  <Link href={`${websiteUrl}/legal/license`} target="_blank">
                    {' '}
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link href={`${websiteUrl}/legal/privacy`} target="_blank">
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
            {errors.acceptedToS && errors.acceptedToS.message && (
              <FormHelperText className={classes.agreeHelperText}>
                {errors.acceptedToS.message}
              </FormHelperText>
            )}
          </FormControl>
          {error && <Alert severity="error">{error}</Alert>}
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            className={classes.submitButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              'Start Your 7 Day Free Trial'
            )}
          </Button>
        </div>
      </form>
    </Container>
  );
};
