import { Container, Grid, Typography, Link, Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SecurityIcon from '@material-ui/icons/Security';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Card from '@material-ui/core/Card';
import React, { useEffect } from 'react';
import config from '../../../config';
import { useStyles } from './styles';
import Referral from '../../../services/referral';

const { websiteUrl, helpUrl } = config;

export const SuccessSignup = ({ data }) => {
  const classes = useStyles();
  const { email, recoveryKey, chargebeeCustomerId } = data;

  useEffect(() => {
    Referral.getInstance().trackSignup({ email, uid: chargebeeCustomerId });
  }, [email, chargebeeCustomerId]);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        <CheckCircleOutlineIcon fontSize="large" />
        Your Registration is Complete!
      </Typography>
      <Typography align="center">Next Steps:</Typography>
      <Card className={classes.mainCard}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={6} direction="column">
            <Typography variant="h6" className={classes.heading}>
              <SecurityIcon />
              Save your secret key
            </Typography>
            <Typography className={classes.content}>
              <i>
                <strong>We take your confidentiality seriously.</strong>
              </i>
              <br />
              This key is used to encrypt your cookies and bookmarks so that
              only you have access to them. You will need this to recover them
              in the event that you lose your login password.
            </Typography>
          </Grid>
          <Grid container item xs={12} md={6} direction="column">
            <div className={classes.downloadSection}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                  recoveryKey
                )}`}
                startIcon={<AttachFileIcon />}
                download="secret-key.txt"
              >
                Download Secret Key
              </Button>
              <Typography>
                <i>
                  <strong>Save this key! </strong>
                  It is only available now and cannot be recovered if lost.
                </i>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
      <Grid container spacing={2}>
        <Grid container item xs={12} md={6} direction="column">
          <Card className={classes.card}>
            <Typography variant="h6" className={classes.heading}>
              <CloudDownloadIcon />
              Get the browser
            </Typography>
            <Typography className={classes.content}>
              If you already have it, return to the Mirage browser and login,
              otherwise, visit the download page to{` `}
              <Link href={`${websiteUrl}/download`} target="_blank">
                get it now
              </Link>
              .
            </Typography>
          </Card>
        </Grid>
        <Grid container item xs={12} md={6} direction="column">
          <Card className={classes.card}>
            <Typography variant="h6" className={classes.heading}>
              <ImportContactsIcon />
              Learn to use MirageID
            </Typography>
            <Typography className={classes.content}>
              Check out our documentation{` `}
              <Link href={helpUrl} target="_blank">
                here.
              </Link>
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
