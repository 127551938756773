import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Shell from './Shell';
import config from './config';

function App() {
  return <Shell />;
}

export default withLDProvider({
  clientSideID: config.launchDarkly.clientID,
  user: {
    key: 'unauthenticated',
  },
})(App);
