import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(4)}px`,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(2)}px`,
  },
  mainCard: {
    padding: `${theme.spacing(4)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    margin: `${theme.spacing(1)}px 0px ${theme.spacing(2)}px`,
  },
  card: {
    padding: `${theme.spacing(4)}px`,
    height: '100%',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(1)}px`,
  },
  downloadSection: {
    padding: `${theme.spacing(2)}px`,
    background: '#d1ecf1',
    height: '100%',
    color: `${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: `${theme.spacing(1)}px`,
    alignItems: 'center',
  },
  content: {
    marginTop: `${theme.spacing(2)}px`,
    color: `${theme.palette.grey[600]}`,
  },
}));
