import React from 'react';
import DataRecoveryModal from 'mirage-components/dist/User/DataRecoveryModal';
import { updateKey, dontRecover } from 'mirage-api-client/dist/user';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { usePassword } from '../entities/PasswordContext';
import RecoveryKey from './RecoveryKey';

export default function DataRecovery({ user }) {
  const { password, setPassword } = usePassword();
  const [open, setOpen] = React.useState(true);
  const { newSnackBar } = useSnackBar();
  const [mirageEncryptedKey, setMirageEncryptedKey] = React.useState(null);

  async function onDelete() {
    try {
      setMirageEncryptedKey(await dontRecover(password));
      newSnackBar({
        type: 'info',
        message:
          'All cloud data has been deleted, a new recovery key has been assinged to you.',
      });
      setPassword('');
      setOpen(false);
    } catch (e) {
      newSnackBar({
        type: 'error',
        message: e.message,
        position: { vertical: 'top', horizontal: 'center' },
      });
    }
  }

  async function onSubmit(key) {
    try {
      await updateKey(key, password);
      newSnackBar({
        type: 'success',
        message: 'Data recovered successfully',
      });
      setPassword('');
      setOpen(false);
    } catch (e) {
      newSnackBar({
        type: 'error',
        message: e.message,
        position: { vertical: 'top', horizontal: 'center' },
      });
    }
  }

  async function onLater() {
    setOpen(false);
  }

  return (
    <>
      <DataRecoveryModal
        open={open}
        canDelete={user && !user.parentUID}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onLater={onLater}
      />
      {mirageEncryptedKey && (
        <RecoveryKey mirageEncryptedKey={mirageEncryptedKey} />
      )}
    </>
  );
}
