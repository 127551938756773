import React from 'react';
import { get } from 'mirage-api-client/dist/user';
import { clearAll as clearCache } from 'mirage-api-client/dist';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import firebase from './firebase';

export const UserContext = React.createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = React.useState(false);
  const ldClient = useLDClient();

  React.useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async firebaseUser => {
        if (firebaseUser) {
          const userResult = await get();
          setUser(userResult);
          if (userResult && userResult.uid && ldClient) {
            ldClient.identify({ key: userResult.uid, email: userResult.email });
          }
        } else {
          // No user is signed in.
          setUser(null);
          clearCache();
        }
      });

    return unsubscribe;
  }, [ldClient]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
  return React.useContext(UserContext);
}
