import * as yup from 'yup';

const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const signupFormModel = yup
  .object({
    email: yup
      .string()
      .required('Please provide a email')
      .email('Email must be valid'),
    accountType: yup.string().required(),
    password: yup
      .string()
      .required('Please provide a password')
      .min(6, 'Password must be at least 6 characters long'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    firstName: yup
      .string()
      .required('Please provide a First Name')
      .min(3, 'First Name must be 3 characters or more'),
    lastName: yup
      .string()
      .min(3, 'Last Name must be 3 characters or more')
      .required('Please provide a Last Name'),
    phone: yup
      .string()
      .required('Please provide a Phone')
      .matches(PHONE_REG_EXP, 'Phone must be valid'),
    address: yup.string().required('Please provide a Address'),
    city: yup.string().required('Please provide a City'),
    state: yup.string().required('Please provide a State'),
    zipcode: yup.string().required('Please provide a ZipCode'),
    country: yup.string().required('Please provide a Country'),
    acceptedToS: yup.bool().oneOf([true], 'You must agree before submitting.'),
  })
  .required();
