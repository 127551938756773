export default class Referral {
  constructor() {
    if (window.$FPROM) {
      this.service = window.$FPROM;
    }
  }

  trackSignup(data, callback) {
    if (this.service) {
      this.service.trackSignup(data, callback);
    }
  }

  static getInstance() {
    if (!this.instance || !this.instance.service) {
      this.instance = new Referral();
    }
    return this.instance;
  }
}
