import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useUser } from 'mirage-api-client/dist/user';
import React from 'react';

import DrawerMenu from './DrawerMenu';
import TopBar from './TopBar';
import { useStyles } from './styles';

export default function Layout({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [user] = useUser();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (matches) handleDrawerOpen();
    else handleDrawerClose();
  }, [matches]);

  return (
    <div className={classes.root}>
      <TopBar
        open={open}
        user={user}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <DrawerMenu user={user} open={open} close={handleDrawerClose} />
      <main className={classes.content}>
        <div
          className={`${classes.toolbar} ${
            user && user.status === 'trial' ? classes.doubleToolbar : ''
          }`}
        />
        {children}
      </main>
    </div>
  );
}
