export const FILTER_SUPPORT_STATUS = {
  APPROVE: 'approve',
  APPROVED: 'approved',
  COMPLETE: 'complete',
  DENY: 'deny',
  DENIED: 'denied',
  FAILED: 'failed',
  UNBLOCK: 'unblock',
};

export const FILTER_SUPPORT_TITLE = {
  DENIED: 'You have denied the access request!',
  EXPIRED: 'Sorry, the link has expired.',
  SENDED: 'Your request has been sent!',
  SUCCESSFULLY: 'You have successfully added the website to the whitelist.',
};
