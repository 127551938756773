import React from 'react';
import RecoveryKeyModal from 'mirage-components/dist/User/RecoveryKeyModal';

function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default function RecoveryKey({ mirageEncryptedKey }) {
  const [open, setOpen] = React.useState(true);

  async function onDownload() {
    setOpen(false);
    download('mirage-recovery-key.txt', mirageEncryptedKey);
  }

  return <RecoveryKeyModal open={open} onDownload={onDownload} />;
}
