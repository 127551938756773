import { Container, Typography, Button } from '@material-ui/core';
import Column from 'mirage-components/dist/Column';
import Row from 'mirage-components/dist/Row';
import DangerConfirmationModal from 'mirage-components/dist/DangerConfirmationModal';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import { formatValues } from '../../../lib/utils';
import { useStyles } from '../styles';

UnblockInfo.prototype = {
  dataJson: PropTypes.shape({
    identity_name: PropTypes.string,
    requestor_email: PropTypes.string,
    email_user_filter_company: PropTypes.string,
    domain: PropTypes.string,
  }).isRequired,
  whiteList: PropTypes.string.isRequired,
  onDeny: PropTypes.func.isRequired,
  onAllow: PropTypes.func.isRequired,
};

export default function UnblockInfo({
  dataJson,
  whiteList = '',
  onDeny,
  onAllow,
}) {
  const classes = useStyles();

  const formatWhiteList = useMemo(() => formatValues(whiteList), [whiteList]);

  const renderItem = (label, value) => (
    <Row className={classes.row}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.text}>{value}</Typography>
    </Row>
  );

  return (
    <Column className={classes.root}>
      <Link to="/">
        <img src={logo} alt="Mirage ID" className={classes.logo} />
      </Link>
      <Typography className={classes.title}>
        Website unblock information
      </Typography>
      <Container className={classes.container}>
        {renderItem('Identity Name:', dataJson.identity_name)}
        {renderItem('MirageId Email:', dataJson.requestor_email)}
        {renderItem('Meshimer Email:', dataJson.email_user_filter_company)}
        {renderItem('Website URL want to unblock:', dataJson.domain)}
      </Container>
      <Container className={classes.container}>
        <Typography className={classes.label}>Allowed Urls</Typography>
        <Column className={classes.heightContainer}>
          {formatWhiteList.length ? (
            formatWhiteList.map((item, index) => (
              <Typography key={index} className={classes.text}>
                {item}
              </Typography>
            ))
          ) : (
            <Typography className={classes.text}>
              No URLs allowed yet
            </Typography>
          )}
        </Column>
      </Container>
      <Row className={`${classes.row} ${classes.button}`}>
        <ConfirmModal
          onClick={onDeny}
          description="Are you sure you want to deny the action?"
        >
          <Button className={classes.actionButton} color="secondary">
            DENY
          </Button>
        </ConfirmModal>
        <ConfirmModal
          onClick={onAllow}
          description="Are you sure you want to add this website to allow list?"
        >
          <Button
            className={classes.actionButton}
            color="primary"
            variant="contained"
          >
            ALLOW
          </Button>
        </ConfirmModal>
      </Row>
    </Column>
  );
}

function ConfirmModal({ children, description, onClick }) {
  const classes = useStyles();

  return (
    <DangerConfirmationModal
      onClick={onClick}
      actionLabel="CONFIRM"
      cancelBtnLabel="NO"
      title=""
      description={description}
      classes={{ paper: classes.paper }}
      customCancelButtonProps={{
        color: 'secondary',
        className: classes.buttonModal,
      }}
      customActionButon={{
        color: 'primary',
        className: classes.buttonModal,
        variant: 'contained',
      }}
      maxWidth={false}
    >
      {children}
    </DangerConfirmationModal>
  );
}
