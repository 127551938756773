import {
  AppBar,
  Button,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Row from 'mirage-components/dist/Row';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../assets/logo.png';
import config from '../config';

const { helpUrl, websiteUrl } = config;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    width: '100%',
    overflowY: 'auto',
    marginTop: `${theme.mixins.toolbar.minHeight}px`,
  },
  toolbar: {
    minHeight: 48,
    justifyContent: 'space-between',
  },
  logo: {
    width: 150,
    position: 'absolute',
    top: 7,
    [theme.breakpoints.down('sm')]: {
      left: `calc(50vw - 75px)`,
    },
  },
  drawer: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mobileCloseMenuButton: {
    marginLeft: 5,
  },
}));

export default function HomeLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div className={classes.root}>
      <TopBar mobile={!matches} />
      <main className={classes.content}>{children}</main>
    </div>
  );
}

function TopBar({ mobile }) {
  const classes = useStyles();
  const { pathname } = useLocation();

  const menuButtons = useMemo(() => {
    const isSignUp = pathname === '/signup';

    return [
      {
        label: 'Features',
        href: `${websiteUrl}/features`,
      },
      {
        label: 'Howto',
        href: helpUrl,
      },
      {
        label: 'Contact Us',
        href: `${websiteUrl}/contact-us`,
      },
      {
        label: 'Pricing',
        href: `${websiteUrl}/pricing`,
      },
      {
        label: isSignUp ? 'Login' : 'Signup',
        href: isSignUp ? '/login' : '/signup',
        style: { minWidth: '72px' },
      },
      {
        label: 'Download',
        href: `${websiteUrl}/download`,
      },
    ];
  }, [pathname]);

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="default"
      elevation={1}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        {mobile && <DrawerMenu menuButtons={menuButtons} />}
        <Link href={websiteUrl}>
          <img src={logo} alt="Mirage ID" className={classes.logo} />
        </Link>
        {!mobile && (
          <Row>
            {menuButtons.map(({ label, href, style }) => (
              <Button key={href} color="inherit" href={href} style={style}>
                {label}
              </Button>
            ))}
          </Row>
        )}
      </Toolbar>
    </AppBar>
  );
}

function DrawerMenu({ menuButtons }) {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return (
    <>
      <IconButton
        onClick={open}
        color="inherit"
        aria-label="open menu"
        edge="start"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={isOpen}
        onClose={close}
        variant="temporary"
        PaperProps={{ className: classes.drawer }}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={close}
            color="inherit"
            aria-label="close menu"
            className={classes.mobileCloseMenuButton}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
        </div>
        <List disablePadding>
          {menuButtons.map(({ label, href }) => {
            return (
              <MenuButton label={label} key={label} href={href} close={close} />
            );
          })}
        </List>
      </Drawer>
    </>
  );
}

function MenuButton({ label, href }) {
  return (
    <ListItem href={href} button component={Link} key={label}>
      <ListItemText primary={label} inset />
    </ListItem>
  );
}
