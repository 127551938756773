import React from 'react';
import { SnackBarProvider } from 'mirage-components/dist/SnackBar';
import { UserProvider } from '../entities/UserContext';
import { PasswordProvider } from '../entities/PasswordContext';
import useAPIClientInit from '../entities/useAPIClientInit';

export default function GlobalProviders({ children }) {
  useAPIClientInit();
  return (
    <UserProvider>
      <PasswordProvider>
        <SnackBarProvider>{children}</SnackBarProvider>
      </PasswordProvider>
    </UserProvider>
  );
}
