import React from 'react';
import { validateKey } from 'mirage-api-client/dist/user';
import { useUser } from './UserContext';
import firebase from './firebase';

export const PasswordContext = React.createContext(null);

export function PasswordProvider({ children }) {
  const [password, setPassword] = React.useState('');
  const [validKey, setValidKey] = React.useState(null);
  const user = useUser();

  // if the user logs in and has the tmpPasssord flag set
  // we want to log them out so that we can capture their password
  // to be used when resetting their password
  if (password.length < 3 && user && user.tmpPassword && document.hasFocus()) {
    firebase.auth().signOut();
  }

  if (user && password && !user.tmpPassword && validKey === null) {
    validateKey(password)
      .then(() => {
        setValidKey(true);
        setPassword('');
      })
      .catch(() => setValidKey(false));
  }

  return (
    <PasswordContext.Provider value={{ password, setPassword, validKey }}>
      {children}
    </PasswordContext.Provider>
  );
}

export function usePassword() {
  return React.useContext(PasswordContext);
}
