import clsx from 'clsx';
import {
  Collapse,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
// import Dashboard from '@material-ui/icons/Dashboard';
import { useTheme } from '@material-ui/core/styles';
import SignOut from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
// import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Column from 'mirage-components/dist/Column';
import { hasPermission } from 'mirage-api-client/dist';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

import firebase from '../../entities/firebase';
import { useStyles } from './styles';

const menuButtons = [
  // {
  //   label: 'Home',
  //   icon: <Dashboard />,
  //   href: '/',
  // },
  {
    label: 'Identities',
    icon: <SupervisedUserCircle />,
    href: '/identities',
    flag: 'identityDropdown',
  },
  {
    label: 'Users',
    icon: <SupervisorAccount />,
    href: '/users',
    permissions: 'user.write',
    flag: 'accountManagement',
  },
  // {
  //   label: 'Billing',
  //   icon: <PaymentIcon />,
  //   href: '/billing',
  //   permissions: 'nonExistentPath',
  //   flag: 'accountPortalBilling',
  // },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    href: '/settings',
  },
  {
    label: 'Sign Out',
    icon: <SignOut style={{ transform: 'rotate(180deg)' }} />,
    onClick: () => firebase.auth().signOut(),
  },
];

export default function DrawerMenu({ user, open, close }) {
  const classes = useStyles();
  const flags = useFlags();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { pathname } = useLocation();

  if (!user) return null;

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      open={matches ? true : open}
      onClose={close}
      variant={matches ? 'permanent' : 'temporary'}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div
        className={`${classes.toolbar} ${
          user && user.status === 'trial' ? classes.doubleToolbar : ''
        }`}
      >
        {!matches && (
          <IconButton
            onClick={close}
            color="inherit"
            aria-label="close drawer"
            className={classes.mobileCloseMenuButton}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Divider />
      </div>

      <Collapse in={open} classes={{ entered: classes.welcome }}>
        <div className={classes.userBox}>
          <Typography variant="caption" color="textSecondary">
            Welcome
          </Typography>
          <Typography
            color="textSecondary"
            className={classes.userEmail}
            noWrap
          >
            {user.email}
          </Typography>
        </div>
        <Divider />
      </Collapse>
      <List disablePadding className={classes.listColumn}>
        {menuButtons.map(
          (
            { label, icon, onClick, href, permissions, flag = null },
            index,
            buttons
          ) => {
            if (
              (flag && !flags[flag]) ||
              (permissions && !hasPermission(user, permissions)) ||
              (user && user.parentUID && href === '/billing')
            )
              return null;

            const active = href && pathname === href;
            const endItem = index === buttons.length - 1;
            const mobile = !matches;

            return (
              <Column key={label} className={endItem ? classes.endItem : ''}>
                {endItem && <Divider />}
                <ListItem
                  onClick={(...args) => {
                    if (typeof onClick === 'function') onClick(...args);
                    if (mobile) close();
                  }}
                  to={href}
                  button
                  component={href ? Link : undefined}
                  classes={{
                    root: classes.listItem,
                    selected: classes.listItemSelect,
                  }}
                  selected={active}
                >
                  <ListItemIcon alt={label}>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              </Column>
            );
          }
        )}
      </List>
    </Drawer>
  );
}
